import React, { forwardRef } from 'react';
import { Avatar as MaterialAvatar, AvatarProps } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import {
  getAvatarBackgroundColor,
  getAvatarTextColor,
  getUserInitials,
} from '@docavenue/chat';
import {
  Practitioner,
  ProfessionalHcd,
  User,
  UserChatRoomDTO,
} from '@maiia/model/generated/model/api-pro/api-pro';

const useStyles = makeStyles<{ backgroundColor; color: string }>()(
  (_theme, { backgroundColor, color = 'white' }) => ({
    initialsAvatar: {
      color,
      backgroundColor,
      '--font-size': 'calc(var(--avatar-size) / 2)',
      fontSize: 'var(--font-size)',
    },
  }),
);

type Props = {
  user: User | Practitioner | ProfessionalHcd | UserChatRoomDTO;
  className?: string;
} & AvatarProps;

const InitialsAvatar = forwardRef(
  (props: Props, ref: React.Ref<HTMLDivElement>): JSX.Element => {
    const { user, className } = props;
    const backgroundColor = getAvatarBackgroundColor(user)!;
    const color = getAvatarTextColor(user) || '';
    const { classes, cx } = useStyles({ backgroundColor, color });
    const initials = getUserInitials(user)!;

    return (
      <MaterialAvatar
        component="div"
        className={cx(className, classes.initialsAvatar, 'initialsAvatar')}
        ref={ref}
      >
        {initials}
      </MaterialAvatar>
    );
  },
);

export default InitialsAvatar;
